<template>
  <Layout>
    <div class="createCoursezz">
      <div class="container">
        <modal-upload></modal-upload>
        <modal-categoria></modal-categoria>
        <ModalDeletarCurso
          :nameCourse="nameCourse"
          :cursoAtual="cursoAtual"
        ></ModalDeletarCurso>
        <b-row class="spaceWizzard">
          <b-col cols="12" md="3" class="pt-5" data-anima="top">
            <div class="navigate">
              <div class="title animationOpacity">
                {{ nameCourse }}
                <button
                  v-if="!existParans"
                  id="viewModuleLesson"
                  class="viewModuleLesson d-flex mt-2"
                  @click="viewCourse()"
                >
                  Ver Curso
                  <IconSax class="ml-2" name="redo" type="outline" size="1rem" color="#c4c4c4" />
                </button>
              </div>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual"
                class="removeLink"
              >
                <div class="spaceNav">
                  <IconSax name="monitor" />
                  <p class="m-0 text ml-3">Dados do Curso</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto"  />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual + '/module'"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="book-square" />
                  <p class="m-0 text ml-3">Módulos e Aulas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/class/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="profile-2user" />
                  <p class="m-0 text ml-3">Turmas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/create-certificates/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="medal-star" />
                  <p class="m-0 text ml-3">Certificado</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <!-- <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/emails/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z" fill="#333333"/>
</svg>
  E-mails</div>
                <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
              </div>
            </router-link> -->
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/vendas/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="bag-2" />
                  <p class="m-0 text ml-3">Vendas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <a
                @click="openModalSoftDelete()"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="trash" />
                  <p class="m-0 text ml-3">Deletar Curso</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto"  />
                </div>
              </a>
              <div class="lineDiv"></div>
              <div class="spaceBtn" v-if="existParans === false">
                <toggle-button
                  :sync="true"
                  :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                  @change="enventSwitchStatus"
                  v-model="myDataVariable"
                  :class="{ disableLink: existParans }"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="9" class="pt-5" data-anima="top">
            <div class="containerWizzard">
              <div class="title">{{ titleText }}</div>
            </div>
            <div class="containerWizzardInputs" v-if="!loading">
              <div class="spaceInputs">
                <b-form-group
                  :label="`Nome completo: ${nameCourse.length}`"
                  label-for="name"
                  id="step4"
                >
                  <b-form-input
                    v-model="nameCourse"
                    maxLength="255"
                    v-validate="'required|max:255|min:5'"
                    placeholder="Nome do curso"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="spaceInputs">
                <div
                  class="gerenciarCat"
                  @click="openModalCategoria"
                  id="step6"
                >
                  Gerenciar Categorias
                </div>
                <b-form-group
                  label="Qual a Categoria do Curso ?"
                  label-for="category"
                  id="step5"
                >
                  <b-form-select
                    v-model="categoryCourse"
                    placeholder="Selecione uma Categoria"
                    :options="AllCategory"
                    value-field="id"
                    text-field="title"
                  ></b-form-select>
                </b-form-group>
              </div>
              <!-- <div class="spaceInputs">
              <b-form-group
                label="Escreva um resumo sobre o seu Curso"
                label-for="resumo"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="descricaoCourse"
                  placeholder="Escreva um resumo sobre o seu Curso"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div> -->
              <!-- <div class="spaceInputs">
              <b-form-group
                label="Escreva um resumo sobre o seu Curso"
                label-for="resumo"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="resumoCourse"
                  placeholder="Escreva um resumo sobre o seu Curso"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div> -->
              <!-- <div class="spaceInputs">
              <b-form-group
                label="Descrição que aparecerá na Thumb do Curso"
                label-for="descricao"
              >

                <Editor 
                  v-model="descricaoAula"
                  :onChange="onEditorChange"
                  placeholder="Descrição que aparecerá na thumb do curso..."
                  />
              </b-form-group>
            </div> -->
              <!-- <div class="spaceInputs">
              <b-form-group
                label="Preview do Curso(Opcional)"
                label-for="intro"
              >
                <div
                  class="btn-openModal"
                  @click="showModalUploadIntro"
                  v-if="midiaintroducaopreview === null"
                >
                  <p class="text">Clique e faça o Upload do Vídeo</p>
                </div>
                <div
                  class="btn-openModalPreview"
                  style="height: 180px;"
                  @click="showModalUploadIntro"
                  v-else
                >
                  <img
                    class="imagePreviewYt animationOpacity"
                    :src="midiaintroducaopreview"
                    :alt="midiaintroducaopreview"
                  />
                </div>
              </b-form-group>
            </div> -->
              <div class="spaceInputs">
                <b-form-group label="Capa do curso" label-for="capa" id="step8">
                  <p class="textDimenssion">
                    Tamanho recomendado para capa vertical: 528 x 710px ou proporcional.<br>
                    Tamanho recomendado para capa horizontal: 528 x 316px ou proporcional.
                  </p>

                  <div
                    class="btn-openModal"
                    @click="showModalUploadCapa"
                    v-if="midiacapa === '' || midiacapa === null"
                  >
                    <p class="text">Clique e faça o Upload da Capa do Curso</p>
                  </div>
                  <div
                    class="btn-openModalPreview"
                    @click="showModalUploadCapa"
                    v-else
                  >
                    <img
                      class="imagePreviewCapa animationOpacity"
                      :src="midiacapaurl"
                      :alt="midiacapa"
                    />
                  </div>
                </b-form-group>
              </div>
              <div class="spaceInputs">
                <button
                  class="btn-criar d-flex align-items-center justify-content-center"
                  @click.prevent="createCourse()"
                  id="step9"
                >
                  {{ btnText }}
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-5" v-if="loading">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/layout/Layout.vue";

import ModalUpload from "@/components/ModalUpload.vue";
import ModalCategoria from "@/components/ModalCategoria.vue";
import CategoryService from "@/services/resources/CategoryService";
import CourseService from "@/services/resources/CourseService";
import { ToggleButton } from "vue-js-toggle-button";
import notify from "@/services/libs/notificacao";
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
const serviceCourse = CourseService.build();
const serviceCategory = CategoryService.build();
import MediasService from "@/services/resources/MediaService";
const serviceMedias = MediasService.build();
import ModalDeletarCurso from "@/components/ModalDeletarCurso.vue";

export default {
  components: {
    Layout,
    ModalUpload,
    ModalCategoria,
    ToggleButton,
    ModalDeletarCurso,
  },
  data() {
    return {
      nameCourse: "",
      nameModulo: "",
      resumoCourse: "",
      descricaoCourse: "",
      categoryCourse: "",
      btnText: "Criar curso agora",
      titleText: "Crie o seu curso",
      step: 0,
      AllCategory: [],
      data: "introducao",
      midiaintroducao: "",
      midiaintroducaopreview: null,
      midiacapa: "",
      midiacapaurl: "",
      midiaaulas: "",
      cursoAtual: "",
      myDataVariable: true,
      existParans: true,
      linkSell: "",
      homeVertical: false,
      loading: false,
    };
  },
  methods: {
    openModalSoftDelete() {
      this.$root.$emit("bv::show::modal", "modal-delete-course", "#btnShow");
    },
    viewCourse() {
      serviceCourse.read(this.cursoAtual + "/module").then((resp) => {
        //console.log("get modules", resp);
        if (resp.length === 0) {
          notify("erro", "Nenhum Módulo Configurado!");
          return false;
        }
        let routeData = this.$router.resolve("/curso/" + this.cursoAtual);
        window.open(routeData.href, "_blank");
      });
    },
    enventSwitchStatus() {
      var xxxStatus = "";
      if (this.myDataVariable === true) {
        xxxStatus = "published";
      } else {
        xxxStatus = "draft";
      }
      let data = {
        id: this.cursoAtual,
        status: xxxStatus,
      };
      //console.log(data);
      this.loading = true;
      serviceCourse
        .postID({ id: this.cursoAtual, status: xxxStatus })
        .then((resp) => {
          this.loading = false;
          //console.log("update course status", resp);
          this.getCourse();
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    createCourse() {
      if (this.existParans === false) {
        var xxxStatus = "";
        if (this.myDataVariable === true) {
          xxxStatus = "published";
        } else {
          xxxStatus = "draft";
        }
        let data = {
          id: this.cursoAtual,
          title: this.nameCourse,
          category_id: this.categoryCourse,
          description: this.descricaoCourse,
          short_description: this.resumoCourse,
          intro: this.midiaintroducao,
          cover: this.midiacapa,
          status: xxxStatus,
        };
        //console.log(data);
        this.loading = true;
        serviceCourse
          .postID(data)
          .then((resp) => {
            this.loading = false;
            //console.log("update course", resp);
            notify("sucesso", "Curso atualizado com Sucesso");
            this.cursoAtual = resp.id;
          })
          .catch((err) => {
            //console.log(err);
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
            }
            if (msg !== "") {
              notify("erro", msg);
            }
            this.loading = false;
          });
      } else {
        if (this.myDataVariable === true) {
          xxxStatus = "published";
        } else {
          xxxStatus = "draft";
        }
        let data = {
          title: this.nameCourse,
          category_id: this.categoryCourse,
          description: this.descricaoCourse,
          short_description: this.resumoCourse,
          intro: this.midiaintroducao,
          cover: this.midiacapa,
          status: xxxStatus,
        };
        //console.log(data);
        this.loading = true;
        serviceCourse
          .create(data)
          .then((resp) => {
            //console.log("created course", resp);
            this.cursoAtual = resp.id;
            this.loading = false;
            notify("sucesso", "Curso criado com Sucesso!");
            this.$router.push("/config_curso/" + resp.id + "/module");
          })
          .catch((err) => {
            //console.log(err);
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
            }
            if (msg !== "") {
              notify("erro", msg);
            }
            this.loading = false;
          });
      }
    },
    getCourse() {
      let idCourse = this.$route.params.curso;
      if (idCourse === null || idCourse === undefined || idCourse === "") {
        this.existParans = true;
        this.btnText = "Criar curso agora";
        this.titleText = "Crie o seu curso";
        this.getIntrojs();
      } else {
        this.loading = true;
        this.cursoAtual = idCourse;
        serviceCourse
          .read(idCourse)
          .then((resp) => {
            this.existParans = false;
            //console.log("get course idCourse", resp);
            this.nameCourse = resp.title;
            this.resumoCourse = resp.short_description;
            this.descricaoCourse = resp.description;
            this.categoryCourse = resp.category.id;
            this.midiacapa = resp.cover.id;
            this.midiacapaurl = resp.cover.cdn_url;

            if (resp.status == "published") {
              this.myDataVariable = true;
            } else {
              this.myDataVariable = false;
            }
            this.btnText = "Atualizar curso agora";
            this.titleText = "Detalhes do curso";
            this.loading = false;
          })
          .catch((err) => {
            //console.log(err);
            this.loading = false;
          });
      }
    },
    openModalCategoria() {
      this.$root.$emit("bv::show::modal", "modal-categoria", "#btnShow");
    },
    showModalUploadIntro() {
      this.$root.$emit("uploadintroducao");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadCapa() {
      this.$root.$emit("uploadcapa");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    getCategory() {
      serviceCategory
        .search()
        .then((resp) => {
          //console.log("category", resp);
          this.categoryCourse = resp[0].id;
          this.AllCategory = resp;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getIntrojs() {
      serviceMember
        .read("/meta?keys[]=onboarding")
        .then((resp) => {
          //console.log("meta onboarding", resp);
          var data = resp.onboarding;
          if (data === null) {
            serviceMedias.read("?type=media&page=1").then((resp) => {
              //console.log("medias uploadeds", resp);
              this.midiacapaurl = resp.data[0].cdn_url;
              this.midiacapa = resp.data[0].id;
            });
            this.nameCourse = "Curso 1";
            this.categoryCourse = 1;
            this.descricaoCourse = "Descrição do Curso 1";
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getHomeVertical() {
      serviceMeta
        .search("keys[]=home_vertical")
        .then((resp) => {
          //console.log("meta helper", resp);
          var data = resp.home_vertical;
          if (data === "off" || data === null) {
            this.homeVertical = false;
          } else {
            this.homeVertical = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  mounted() {
    this.getCategory();
    this.getCourse();
    this.getHomeVertical();
    this.$root.$on("bv::modal::hidden", (data) => {
      if (data.componentId === "modal-categoria") {
        this.getCategory();
      }
    });
    this.$root.$on("midiaintroducaovimeo", (data) => {
      this.midiaintroducao = data.link;
      this.midiaintroducaopreview = data.pictures.sizes[0].link;
      this.midiaintroducaotype = "vimeo";
    });
    this.$root.$on("midiaintroducaoyt", (data) => {
      this.midiaintroducao =
        "https://www.youtube.com/watch?v=" + data.id.videoId;
      this.midiaintroducaopreview = data.snippet.thumbnails.default.url;
      this.midiaintroducaotype = "youtube";
    });
    this.$root.$on("midiaintroducaopanda", (data) => {
      this.midiaintroducao = data.video_player;
      this.midiaintroducaopreview = data.video_player;
      this.midiaintroducaotype = "panda";
    });
    this.$root.$on("midiacapa", (data) => {
      //console.log("fdhsjkjdshfkjhkjdshkjfs", data);
      this.midiacapa = parseInt(data.id);
      this.midiacapaurl = data.url;
    });
    this.$root.$on("midiaaulas", (data) => {
      this.midiaaulas = parseInt(data);
    });
    this.$root.$on("stepconcluircurso", (data) => {
      this.createCourse();
    });
  },
};
</script>
<style lang="scss">
.btn-openModalPreview {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  img {
    border-radius: 3px;
  }
  .imagePreviewCapa {
    height: 150px;
  }
}
.createCoursezz {
  input#rangeCertify {
    border: none !important;
    box-shadow: none !important;
  }
  .textPorcentage {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    margin-top: -10px;
  }
  label {
    color: var(--fontcolor) !important;
  }
  .removeLink {
    text-decoration: none;
  }
  .disableLink {
    pointer-events: none;
  }
  .ql-editor {
    min-height: 100px !important;
  }
  .navigate {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor) !important;
      word-break: break-word;
      margin-bottom: 5px;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(0, 35, 99, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        border: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #002363;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      .text {
        font-family: Inter;
        font-style: normal;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }
  .editModuleClass {
    position: fixed;
    background: rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999999999;
    .contentAdd {
      z-index: 99999999999999999999999999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 25vh;
      width: 303px;
      height: 295px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      box-sizing: border-box;
      border-radius: 3px;
      .close {
        cursor: pointer;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ededf0;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor) !important;
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceWizzard {
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 8px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: var(--fontcolor) !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: var(--fontcolor) !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputsCreate {
      display: flex;
      justify-content: flex-end;
      position: relative;
      left: 5em;
      top: -2em;
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        height: 55px;
        margin-top: 40px;
        width: 250px;
      }
    }
    .containerWizzardInputs {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      margin-top: 50px;
      .paddingNewModule {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 10px;
      }
      .btn-newModule {
        background: rgba(129, 133, 142, 0.03);
        border: 2px dashed #81858e;
        box-sizing: border-box;
        border-radius: 30px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #81858e;
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .removemb {
        margin-bottom: 0px !important;
      }
      .actionModule {
        display: flex;
        justify-content: space-between;
        .titleModule {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--maincolor);
        }
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: var(--fontcolor) !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: var(--fontcolor) !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
      .lineDiv {
        background: #ededf0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 0.5px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .spaceModuleCard {
        width: 100%;
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 32px;
        margin-bottom: 20px;
      }
      .spaceInputs {
        select {
          background-color: var(--backgroundcolor) !important;
        }
        margin-bottom: 30px;
        .textInfo {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #81858e;
          margin-top: 5px;
        }
        .btn-criar {
          background: var(--maincolor);
          border: 1px solid var(--maincolor);
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 30px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          width: 100%;
          height: 55px;
          margin-top: 40px;
        }
        .spaceInputs {
          margin-bottom: 30px;
          .textInfo {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #81858e;
            margin-top: 5px;
          }
          .btn-criar {
            background: var(--maincolor);
            border: 1px solid var(--maincolor);
            box-sizing: border-box;
            box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
            border-radius: 30px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
            width: 100%;
            height: 55px;
            margin-top: 40px;
          }
          .btn-openModalPreview {
            background: var(--maincolortrans);
            border: 2px dashed var(--maincolor);
            box-sizing: border-box;
            border-radius: 30px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            padding-top: 20px;
            padding-bottom: 20px;
            img {
              border-radius: 3px;
            }
            .imagePreviewCapa {
              width: 100px;
              height: 100px;
              border-radius: 3px;
              overflow: hidden;
            }
          }
          .btn-openModal {
            background: var(--maincolortrans);
            border: 2px dashed var(--maincolor);
            box-sizing: border-box;
            border-radius: 30px;
            height: 55px;
            cursor: pointer;
            .text {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              color: var(--maincolor);
              display: flex;
              justify-content: center;
              margin-top: 17px;
            }
          }
          .gerenciarCat {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.2px;
            color: var(--maincolor);
            position: absolute;
            right: 3em;
            cursor: pointer;
          }
          select {
            border: 1px solid var(--bordercolor);
            box-sizing: border-box;
            border-radius: 30px;
            height: 55px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #6d767e;
            overflow: hidden !important;
            resize: none !important;
          }
          input,
          textarea {
            border: 1px solid var(--bordercolor);
            box-sizing: border-box;
            border-radius: 30px;
            height: 55px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
            overflow: hidden !important;
            resize: none !important;
            background: white;
          }
          input:hover,
          select:hover,
          textarea:hover,
          input:focus,
          select:focus,
          textarea:focus {
            border: 1px solid var(--maincolor);
            box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
          }
          label {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
          }
        }
        .btn-openModal {
          background: var(--maincolortrans);
          border: 2px dashed var(--maincolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          cursor: pointer;
          .text {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: var(--maincolor);
            display: flex;
            justify-content: center;
            margin-top: 17px;
          }
        }
        .gerenciarCat {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          position: absolute;
          right: 3em;
          cursor: pointer;
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        input,
        textarea {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        select:hover,
        textarea:hover,
        input:focus,
        select:focus,
        textarea:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
      }
    }
    .containerWizzard {
      text-align: center;
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        align-items: center;
        color: var(--fontcolor) !important;
      }
    }
  }
  .containerx {
    width: 98%;
  }
}
</style>
